import type { EndpointTypes } from '@models/types';
import { Connection } from '@solana/web3.js';
import type { EndpointInfo as OriginalEndpointInfo } from '../@types/types';

// Extending the original EndpointInfo type to include WebSocket URL
interface EndpointInfo extends OriginalEndpointInfo {
  wssUrl: string;  // WebSocket URL
}

const ENDPOINTS: EndpointInfo[] = [
  {
    name: 'mainnet',
    url:  process.env.NEXT_PUBLIC_MAINNET_RPC || 'http://realms-realms-c335.mainnet.rpcpool.com/258d3727-bb96-409d-abea-0b1b4c48af29/',
    wssUrl: process.env.NEXT_PUBLIC_MAINNET_WSS || 'wss://mainnet-ws-url', // Replace with actual WSS URL
  },
  {
    name: 'devnet',
    url: process.env.NEXT_PUBLIC_DEVNET_RPC || 'https://api.dao.devnet.solana.com/',
    wssUrl: process.env.NEXT_PUBLIC_DEVNET_WSS || 'wss://devnet-ws-url', // Replace with actual WSS URL
  },
  {
    name: 'localnet',
    url: 'http://127.0.0.1:8899',
    wssUrl: 'ws://127.0.0.1:8900', // Local WebSocket URL (adjust if different)
  },
];



export interface ConnectionContext {
  cluster: EndpointTypes;
  current: Connection;
  endpoint: string;
 // wssEndpoint: string; // WebSocket URL
}

export function getConnectionContext(cluster: string): ConnectionContext {
  const ENDPOINT = ENDPOINTS.find((e) => e.name === cluster) || ENDPOINTS[0];

  return {
    cluster: ENDPOINT!.name as EndpointTypes,
    current: new Connection(ENDPOINT!.url, { wsEndpoint: ENDPOINT!.wssUrl }),
    endpoint: ENDPOINT!.url,
    //wssEndpoint: ENDPOINT!.wssUrl,
  };
}


/**
 * Given ConnectionContext, find the network.
 * @param connectionContext
 * @returns EndpointType
 */
export function getNetworkFromEndpoint(endpoint: string) {
  const network = ENDPOINTS.find((e) => e.url === endpoint)
  if (!network) {
    console.log(endpoint, ENDPOINTS)
    throw new Error(`Network not found for endpoint: ${endpoint}`)
  }
  return network?.name
}
