import { useEffect } from 'react'
import { useRouter } from 'next/router'

export const CustomRouter = () => {
  const router = useRouter()

  useEffect(() => {
    // Check if there's a saved route in localStorage
    const savedRoute = localStorage.getItem('savedRoute')

    if (savedRoute) {
      // Remove the saved route from localStorage
      localStorage.removeItem('savedRoute')

      // Use router.push() to navigate to the saved route
      router.push(savedRoute)
    } else {
      // If no saved route, use the current URL path
      const currentPath = window.location.pathname
      router.push(currentPath)
    }
  }, [])

  return null
}
