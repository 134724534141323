export const NEXT_PUBLIC_MAINNET_RPC =
  process.env.NEXT_PUBLIC_MAINNET_RPC ||
  process.env.NEXT_PUBLIC_MAINNET_RPC ||
  'http://realms-realms-c335.mainnet.rpcpool.com/258d3727-bb96-409d-abea-0b1b4c48af29/'

export const NEXT_PUBLIC_DEVNET_RPC =
  process.env.NEXT_PUBLIC_DEVNET_RPC ||
  process.env.NEXT_PUBLIC_DEVNET_RPC ||
  'https://mango.devnet.rpcpool.com'

export const NEXT_PUBLIC_MAINNET_RPC_WSS =
  process.env.NEXT_PUBLIC_MAINNET_RPC_WSS ||
  process.env.NEXT_PUBLIC_MAINNET_RPC_SS ||
  'https://mango.devnet.rpcpool.com'
